// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-hero-hero-animation-hero-animation-js": () => import("./../src/pages/home/Hero/HeroAnimation/HeroAnimation.js" /* webpackChunkName: "component---src-pages-home-hero-hero-animation-hero-animation-js" */),
  "component---src-pages-home-hero-hero-animation-index-js": () => import("./../src/pages/home/Hero/HeroAnimation/index.js" /* webpackChunkName: "component---src-pages-home-hero-hero-animation-index-js" */),
  "component---src-pages-home-hero-hero-js": () => import("./../src/pages/home/Hero/Hero.js" /* webpackChunkName: "component---src-pages-home-hero-hero-js" */),
  "component---src-pages-home-hero-index-js": () => import("./../src/pages/home/Hero/index.js" /* webpackChunkName: "component---src-pages-home-hero-index-js" */),
  "component---src-pages-home-home-js": () => import("./../src/pages/home/home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-project-section-graph-js": () => import("./../src/pages/home/ProjectSection/Graph.js" /* webpackChunkName: "component---src-pages-home-project-section-graph-js" */),
  "component---src-pages-home-project-section-index-js": () => import("./../src/pages/home/ProjectSection/index.js" /* webpackChunkName: "component---src-pages-home-project-section-index-js" */),
  "component---src-pages-home-project-section-message-animation-js": () => import("./../src/pages/home/ProjectSection/MessageAnimation.js" /* webpackChunkName: "component---src-pages-home-project-section-message-animation-js" */),
  "component---src-pages-home-project-section-project-box-index-js": () => import("./../src/pages/home/ProjectSection/ProjectBox/index.js" /* webpackChunkName: "component---src-pages-home-project-section-project-box-index-js" */),
  "component---src-pages-home-project-section-project-box-project-box-js": () => import("./../src/pages/home/ProjectSection/ProjectBox/ProjectBox.js" /* webpackChunkName: "component---src-pages-home-project-section-project-box-project-box-js" */),
  "component---src-pages-home-project-section-project-section-js": () => import("./../src/pages/home/ProjectSection/ProjectSection.js" /* webpackChunkName: "component---src-pages-home-project-section-project-section-js" */),
  "component---src-pages-home-purple-section-hexagon-animation-js": () => import("./../src/pages/home/PurpleSection/HexagonAnimation.js" /* webpackChunkName: "component---src-pages-home-purple-section-hexagon-animation-js" */),
  "component---src-pages-home-purple-section-hexagon-hexagon-js": () => import("./../src/pages/home/PurpleSection/Hexagon/Hexagon.js" /* webpackChunkName: "component---src-pages-home-purple-section-hexagon-hexagon-js" */),
  "component---src-pages-home-purple-section-hexagon-index-js": () => import("./../src/pages/home/PurpleSection/Hexagon/index.js" /* webpackChunkName: "component---src-pages-home-purple-section-hexagon-index-js" */),
  "component---src-pages-home-purple-section-index-js": () => import("./../src/pages/home/PurpleSection/index.js" /* webpackChunkName: "component---src-pages-home-purple-section-index-js" */),
  "component---src-pages-home-purple-section-line-index-js": () => import("./../src/pages/home/PurpleSection/Line/index.js" /* webpackChunkName: "component---src-pages-home-purple-section-line-index-js" */),
  "component---src-pages-home-purple-section-line-line-js": () => import("./../src/pages/home/PurpleSection/Line/Line.js" /* webpackChunkName: "component---src-pages-home-purple-section-line-line-js" */),
  "component---src-pages-home-purple-section-purple-section-js": () => import("./../src/pages/home/PurpleSection/PurpleSection.js" /* webpackChunkName: "component---src-pages-home-purple-section-purple-section-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

